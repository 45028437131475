<template>
  <div class="blog-area ">
    <div class="container">
      <div class="row">
        <div class="blog-items">
          <div class="col-lg-12">
            <h2>{{note.title}}</h2>
            <img :src="note.img" style="width: 100%" alt="">
            <h4 style="margin-top: 20px">{{note.description_crop}}</h4>
            <div v-html="note.description">

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Nota",
  data() {
    return {
      note: {
        img:null
      }
    }
  },
  methods: {
    ...mapActions('blog', ['getSlugEvent']),
    async getSlug(slug) {
      this.note = await this.getSlugEvent(slug)
    }
  },
  async created() {
    const slug = this.$route.params.slug;
    await this.getSlug(slug)

  }
}
</script>

<style scoped>

</style>
